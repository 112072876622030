<template>
<div>
    <BasePageBreadCrumb :pages="pages" title="perfil" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0 p-dash-cliente-master">
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-12 col-lg-12 pt-3">
                    <div class="card card-bg-dash">
                        <div class="card-body pt-4 px-0">
                            <b-tabs>
                                <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <b-tab class="tab-pane" id="dadospessoais">
                                    <template #title>
                                        <div class="d-flex">
                                            <i class="far fa-list-alt font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Dados pessoais</span>
                                        </div>
                                    </template>

                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group mb-2">
                                                    <label>Tipo de cadastro </label>
                                                </div>
                                                <b-form-checkbox-group
                                                    v-model="client.type"
                                                    name="">
                                                    <div class="form-check form-check-inline">
                                                        <b-form-checkbox 
                                                        value="CLIENT" disabled>Cliente/Proprietário</b-form-checkbox>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <b-form-checkbox  
                                                        value="LESSE" disabled>Locatário</b-form-checkbox>
                                                    </div>
                                                </b-form-checkbox-group>

                                            </div>
                                            <div class="col-12 pt-4"></div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Código do cliente</label>
                                                    <input type="text" class="form-control" placeholder="Gerado automaticamente" v-model="id" disabled>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Cliente desde </label>
                                                    <input type="date" class="form-control" placeholder="21/01/2021" name="client_since_date" v-model="client.client_since_date" v-validate="'required'" :class="{'is-invalid': errors.has('client_since_date')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Data cadastro </label>
                                                    <input type="date" class="form-control" placeholder="21/01/2021" disabled>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Como conheceu a empresa? </label>
                                                    <select class="custom-select mr-sm-2" v-model="client.source" name="source" v-validate="'required'" :class="{'is-invalid': errors.has('source')}">
                                                        <option :value="null">Selecione</option>
                                                        <option value="GOOGLE">Google</option>
                                                        <option value="SOCIAL_MEDIA">Rede Social</option>
                                                        <option value="RADIO">Rádio</option>
                                                        <option value="OUTDOOR">Outdoor</option>
                                                        <option value="MAGAZINE">Revista</option>
                                                        <option value="INDICATION">Indicação</option>
                                                        <option value="OTHER">Outros</option>
                                                    </select>
                                                </div>
                                                <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Definir a origem do lead</small>
                                            </div>
                                            <div class="col-12 pt-4"></div>
                                            <div class="col-12 col-md-6 col-lg-5">
                                                <div class="form-group">
                                                    <label>Nome </label>
                                                    <input type="text" class="form-control" v-model="client.first_name" v-validate="'required'" name="first_name" :class="{'is-invalid': errors.has('first_name')}" disabled>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="form-group">
                                                    <label>Sobrenome </label>
                                                    <input type="text" class="form-control" v-model="client.last_name" v-validate="'required'" name="last_name" :class="{'is-invalid': errors.has('last_name')}" disabled>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>CPF </label>
                                                    <input type="tel" class="form-control" placeholder="000.000.000-00" v-model="client.cpf" name="cpf" v-validate="'required'" :class="{'is-invalid': errors.has('cpf')}" v-mask="'###.###.###-##'" disabled>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Data de nascimento </label>
                                                    <input type="date" class="form-control" placeholder="" v-model="client.birth_date" name="birth_date" v-validate="'required'" :class="{'is-invalid': errors.has('birth_date')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Sexo </label>
                                                    <select class="custom-select mr-sm-2" v-model="client.gender" v-validate="'required'" name="gender" :class="{'is-invalid': errors.has('gender')}">
                                                        <option :value="null">Selecione</option>
                                                        <option value="MALE">Masculino</option>
                                                        <option value="FEMALE">Feminino</option>
                                                        <option value="NONE">Não quero informar</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Estado civil </label>
                                                    <select class="custom-select mr-sm-2" v-model="client.marital_status" v-validate="'required'" name="marital_status" :class="{'is-invalid': errors.has('marital_status')}">
                                                        <option :value="null">Selecione</option>
                                                        <option value="SINGLE">Solteiro(a)</option>
                                                        <option value="MARRIED_TOTAL">Casado(a) Comunhão de Bens</option>
                                                        <option value="MARRIED_PARCIAL">Casado(a) Comunhão Parcial de Bens</option>
                                                        <option value="MARRIED_NONE">Casado(a) Separação de Bens</option>
                                                        <option value="DIVORCED">Divorciado(a)</option>
                                                        <option value="SEPARATE">Separado(a) Judicialmente</option>
                                                        <option value="WIDOWER">Viúvo(a)</option>
                                                        <option value="OTHERS">União Estável/Outros</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Grau de instrução </label>
                                                    <select class="custom-select mr-sm-2" v-model="client.alphabetization" v-validate="'required'"  name="alphabetization" :class="{'is-invalid': errors.has('alphabetization')}">
                                                        <option :value="null">Selecione</option>
                                                        <option value="NONE">Não Alfabetizado</option>
                                                        <option value="ELEMENTARY_INCOMPLETE">Ensino Fundamental Incompleto</option>
                                                        <option value="ELEMENTARY_COMPLETE">Ensino Fundamental Completo</option>
                                                        <option value="HIGH_SCHOOL_INCOMPLETE">Médio Incompleto</option>
                                                        <option value="HIGH_SCHOOL_COMPLETE">Médio Completo</option>
                                                        <option value="COLLEGE_INCOMPLETE">Superior Incomplet</option>
                                                        <option value="COLLEGE_COMPLETE">Superior Completo</option>
                                                        <option value="ESPECIALIZATION">Especialização2</option>
                                                        <option value="MASTER">Mestrado</option>
                                                        <option value="DOCTORATE">Doutorado</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Nacionalidade </label>
                                                    <select class="custom-select mr-sm-2" v-model="client.nacionality" v-validate="'required'" name="nacionality" :class="{'is-invalid': errors.has('nacionality')}">
                                                        <option :value="null">Selecione</option>
                                                        <option value="BRAZILIAN">Brasileira</option>
                                                        <option value="FOREIGN">Estrangeira</option>
                                                        <option value="CIVILIZED">Brasileira Naturalizado</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>Naturalidade </label>
                                                    <div class="row">
                                                        <div class="col-12 col-md-6">
                                                            <select class="custom-select mr-sm-2" name="birth_state" v-model="client.born_state" v-validate="'required'" :class="{'is-invalid': errors.has('birth_state')}" >
                                                                <option :value="null">Selecione</option>
                                                                <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <select class="custom-select mr-sm-2" v-model="client.born_city" v-validate="'required'" name="birth_city" :class="{'is-invalid': errors.has('birth_city')}">
                                                                <option :value="null">Selecione</option>
                                                                <option v-for="item in getCities(client.born_state)" :key="item" :value="item">{{ item }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-12"></div>
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>Nome do pai </label>
                                                    <input type="text" class="form-control" placeholder="" v-model="client.father_name" v-validate="'required'" name="father_name" :class="{'is-invalid': errors.has('father_name')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>Nome do mãe </label>
                                                    <input type="text" class="form-control" v-model="client.mother_name" v-validate="'required'" name="mother_name" :class="{'is-invalid': errors.has('mother_name')}">
                                                </div>
                                            </div>
                                            <div class="col-12"></div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Número da identidade </label>
                                                    <input type="tel" class="form-control"  v-model="client.rg" v-validate="'required'" name="rg" :class="{'is-invalid': errors.has('rg')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Órgão emissor </label>
                                                    <input type="text" class="form-control" v-model="client.rg_emissor" v-validate="'required'" name="rg_emissor" :class="{'is-invalid': errors.has('rg_emissor')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>UF </label>
                                                    <select class="custom-select mr-sm-2" v-model="client.rg_uf" v-validate="'required'" name="rg_uf" :class="{'is-invalid': errors.has('rg_uf')}">
                                                        <option :value="null">Selecione</option>
                                                        <option v-for="item in states" :key="item.sigla" :value="item.sigla">{{ item.nome }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Data de emissão </label>
                                                    <input type="date" class="form-control" placeholder="" v-model="client.rg_date" v-validate="'required'" name="rg_date" :class="{'is-invalid': errors.has('rg_date')}">
                                                </div>
                                            </div>
                                            <div class="col-12"></div>
                                            <br>
                                            <div class="col-12 py-2">
                                                <h4 class="card-title">Contatos</h4>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="form-group">
                                                    <label>E-mail </label>
                                                    <input type="email" class="form-control" placeholder="abc@example.com" v-model="client.email" v-validate="'required|email'" name="email" :class="{'is-invalid': errors.has('email')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="form-group">
                                                    <label>Telefone </label>
                                                    <input type="tel" class="form-control" placeholder="(44) 4444-4444" v-model="client.telephone" v-validate="'required'" name="telephone" :class="{'is-invalid': errors.has('telephone')}" v-mask="'(##) ####-####'"> 
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="form-group">
                                                    <label>Whatsapp </label>
                                                    <input type="tel" class="form-control" placeholder="(44) 4 4444-4444" v-model="client.cellphone" v-validate="'required'" name="cellphone" :class="{'is-invalid': errors.has('cellphone')}" v-mask="['(##) ####-####', '(##) # ####-####']">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab class="tab-pane" id="dadosdoconjuge">
                                    <template #title>
                                        <div class="d-flex">
                                            <i class="far fa-address-book font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Dados do cônjuge</span>
                                        </div>
                                    </template>
                                    <div class="col-12">
                                        <div class="row" >
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>Nome </label>
                                                    <input type="text" class="form-control" placeholder="" v-model="client.partner_first_name">
                                                    <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu nome.</div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>Sobrenome </label>
                                                    <input type="text" class="form-control" placeholder="" v-model="client.partner_last_name">
                                                    <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu sobrenome.</div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>CPF </label>
                                                    <input type="tel" class="form-control" placeholder="000.000.000-00" v-model="client.partner_cpf" v-mask="'###.###.###-##'">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Data de nascimento </label>
                                                    <input type="date" class="form-control" placeholder="" v-model="client.partner_birth_date">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab class="tab-pane" id="enderecoresidencial">
                                    <template #title>
                                        <div class="d-flex">
                                            <i class="far fa-map font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Endereço residencial <b-badge variant="danger" v-show="address_error">1</b-badge></span>
                                        </div>
                                    </template>
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col-12 col-md-4 col-lg-3">
                                                <div class="form-group">
                                                    <label>CEP </label>
                                                    <input type="tel" class="form-control" placeholder="74.830-50" v-model="client.cep" name="cep" v-validate="'required'" :class="{'is-invalid': errors.has('cep')}" v-mask="'##.###-##'">
                                                </div>
                                            </div>
                                            <div class="col-12"></div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="form-group">
                                                    <label>Rua/Avenida/Alameda/Travessa </label>
                                                    <input type="text" class="form-control" placeholder="" v-model="client.street" v-validate="'required'" name="street" :class="{'is-invalid': errors.has('street')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-2">
                                                <div class="form-group">
                                                    <label>Número </label>
                                                    <input type="text" class="form-control" placeholder="" v-model="client.number" v-validate="'required'" name="number" :class="{'is-invalid': errors.has('number')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>Complemento </label>
                                                    <input type="text" class="form-control" placeholder="" v-model="client.complement" name="complement" v-validate="'required'" :class="{'is-invalid': errors.has('complement')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label>Bairro </label>
                                                    <input type="text" class="form-control" placeholder="" v-model="client.neighborhood" name="neighborhood" v-validate="'required'" :class="{'is-invalid': errors.has('neighborhood')}">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-2">
                                                <div class="form-group">
                                                    <label>UF </label>
                                                    <select class="custom-select mr-sm-2" v-model="client.state" v-validate="'required'" name="uf" :class="{'is-invalid': errors.has('uf')}">
                                                        <option :value="null">Selecione</option>
                                                        <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6 col-lg-4">
                                                <div class="form-group">
                                                    <label>Município </label>
                                                    <select class="custom-select mr-sm-2" v-model="client.city" v-validate="'required'" name="city" :class="{'is-invalid': errors.has('city')}">
                                                        <option :value="null">Selecione</option>
                                                        <option v-for="item in getCities(client.state)" :key="item" :value="item">{{ item }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            <div class="col-12 col-md-6 col-lg-3">
                                                <div class="form-group">
                                                    <label>Sua residência é </label>
                                                    <select class="custom-select mr-sm-2" v-model="client.residence_type" name="residence_type" v-validate="'required'" :class="{'is-invalid': errors.has('residence_type')}">
                                                        <option :value="null">Selecione</option>
                                                        <option value="LEASED">Alugada</option>
                                                        <option value="PARENTS">Mora com parentes/pais</option>
                                                        <option value="GIVEN">Cedida/Funcional</option>
                                                        <option value="OWN">Própria quitada</option>
                                                        <option value="FINANCED_CAIXA">Financiada pela CAIXA</option>
                                                        <option value="FINANCED">Financiada por outras entidades</option>
                                                        <option value="OTHERS">Outros</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab class="tab-pane" id="imoveis" v-if="active_enterprise == 1">
                                    <template #title>
                                        <div class="d-flex">
                                            <i class="far fa-building font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Imóveis <b-badge variant="danger" v-show="enterprise_error">1</b-badge></span>
                                        </div>
                                    </template>
                                        <div class="card-list-legenda py-2">
                                            <div class="card-body-list-legend d-none d-lg-block">
                                                <div class="col-12 p-0 align-self-center">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status">
                                                                <p class="text-truncate title-legend-list"><i class="fa fa-circle color-ico-legenda font-12" data-toggle="tooltip" data-placement="top" title="Status"></i></p>
                                                            </div>
                                                            <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                                                <p class="text-truncate title-legend-list" style="width:45px">&nbsp;</p>
                                                            </div>
                                                            <div class="col-5 col-md-8 col-lg-9 p-0 order-3 align-self-center">
                                                                <div class="col-12">
                                                                    <div class="row">
                                                                        <div class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list">
                                                                            <p class="text-truncate title-legend-list">Imóvel</p>
                                                                        </div>
                                                                        <div class="col-12 col-md-6 col-lg-3 align-self-center text-muted pad-content-list">
                                                                            <p class="text-truncate title-legend-list">Tipo</p>
                                                                        </div>
                                                                        <div class="col-auto col-md-auto col-lg-1 align-self-center text-muted pad-content-list">
                                                                            <p class="text-truncate title-legend-list">N˚ torre</p>
                                                                        </div>
                                                                        <div class="col-auto col-md-auto col-lg-1 align-self-center text-muted pad-content-list">
                                                                            <p class="text-truncate title-legend-list">N˚ apto.</p>
                                                                        </div>
                                                                        <div class="col-auto col-md-auto col-lg-2 align-self-center pad-content-list d-none d-md-block">
                                                                            <p class="text-truncate title-legend-list">ÁREA DO TERRENO (M²)</p>
                                                                        </div>
                                                                        <div class="col-12 col-md-6 col-lg-2 align-self-center pad-content-list d-none d-md-block">
                                                                            <p class="text-truncate title-legend-list">Status</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-2 col-lg-auto align-self-center pad-content-list order-4">
                                                                <p class="text-truncate title-legend-list"></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-list" v-for="(item, index) in client.enterprises" :key="item.id">
                                            <div class="card-body-list">
                                                <div class="col-12 p-0 align-self-center">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block">
                                                                <i class="fa fa-circle text-success font-12" data-toggle="tooltip" data-placement="top" title="Status"></i>
                                                            </div>
                                                            <div class="col-auto col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                                                <img v-if="item.image" :src="item.image" alt="user" class="rounded-circle card-body-list-img" />
                                                            </div>
                                                            <div class="col-5 col-md-8 col-lg-9 p-0 order-3 align-self-center">
                                                                <div class="col-12">
                                                                    <div class="row">
                                                                        <div class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list">
                                                                            <h6 class="text-truncate cod"><span>CÓD.:</span> {{ item.cod }}</h6>
                                                                            <h6 class="text-truncate card-body-list-title">{{ item.name }}</h6>
                                                                        </div>
                                                                        <div class="col-12 col-md-6 col-lg-3 align-self-center text-muted pad-content-list">
                                                                            <h6 class="text-truncate empreendi">Empreendimento</h6>
                                                                        </div>
                                                                        <div class="col-auto col-md-auto col-lg-1 align-self-center text-muted pad-content-list">
                                                                            <h6 class="text-truncate empreendi">{{ item.tower }}</h6>
                                                                        </div>
                                                                        <div class="col-auto col-md-auto col-lg-1 align-self-center text-muted pad-content-list">
                                                                            <h6 class="text-truncate empreendi">{{ item.ground_floor }}</h6>
                                                                        </div>
                                                                        <div class="col-auto col-md-auto col-lg-2 mx-auto align-self-center pad-content-list d-none d-md-block">
                                                                            <h6 class="text-truncate torre">{{ item.area }}</h6>
                                                                        </div>
                                                                        <div class="col-12 col-md-6 col-lg-2 align-self-center pad-content-list">
                                                                            <h6 class="status-imovel"><small class="badge form-text text-white" style="background-color: #563d7c;">Em construção</small></h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 col-md-2 col-lg-auto order-3 p-lg-3 p-0 ml-auto text-right align-self-center text-muted">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="client.enterprises.length == 0" class="row justify-content-center">
                                            Sem imóveis
                                        </div>

                                        <div class="col-12 pt-4"></div>
                                        <div class="col-12 pb-2">
                                            <BaseEnterpriseBox v-model="enterprise_id" />

                                        </div>
                                        <div class="col-12 pt-3"></div>
                                        <div class="col-12 pb-2">
                                            <BaseAptBox v-model="floors" :enterprise_id="enterprise_id" />

                                        </div>

                                        <b-button type="button" class="btn btn-info" @click="addEnterprise">Adicionar</b-button>
                                </b-tab>
                                  <b-tab class="tab-pane" id="configuracoes">
                                    <template #title>
                                        <div class="d-flex">
                                            <i class="far fa-sun font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Configurações <b-badge variant="danger" v-show="settings_error">1</b-badge></span>
                                        </div>
                                    </template>
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-4" v-show="isAdmin">
                                                    <div class="form-group">
                                                        <label>Status do cadastro </label>
                                                        <select class="custom-select mr-sm-2" v-model="client.is_active">
                                                            <option :value="true">Ativo</option>
                                                            <option :value="false">Inativo</option>
                                                        </select>
                                                    </div>
                                                    <small id="name13" class="badge badge-default badge-danger form-text text-white">Desativar o acesso do cliente na plataforma</small>
                                                </div>
                                                <div class="col-12 pt-4 pb-2">
                                                    <h4 class="card-title">Senha de acesso</h4>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Resetar senha </label>
                                                        <input type="password" class="form-control" id="passtext" placeholder="********" v-model="client.password" v-validate="'min:8'" ref="password" name="password" :class="{'is-invalid': errors.has('password')}">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Confirmar senha </label>
                                                        <input type="password" class="form-control" id="passtext" placeholder="********" v-validate="'min:8|confirmed:password'" name="confirm_password" :class="{'is-invalid': errors.has('confirm_password')}">
                                                    </div>
                                                    <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                                </div>
                                            </div>
                                        </div>
                             
                                </b-tab>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="reset" class="btn btn-dark">Cancelar</button>
                                        <button type="submit" class="btn btn-info ml-2">Atualizar</button>
                                    </div>
                                </div>
                                </form>
                            </b-tabs>
                        </div>
                    </div>
                </div>
                <!--<div class="col-12 col-md-12 col-lg-3 px-0 pt-tudoemumsolugar">
                    <div class="col-12 d-flex">
                        <div class="text-center" style="position: absolute; z-index:2; bottom:0; right: 0;">
                            <div class="w-100">
                                <img src="~@/assets/img/pessoa-tudo.png" class="img-fluid mx-auto" style="" alt="Pessoa">
                                max-height:320px; max-width: 400px;
                            </div>
                        </div>
                        <div class="col-12 col-md-12 col-lg-9 ml-auto d-flex p-0" style="background-size:cover; background-position:top center; border-radius: 22px; margin-bottom: 30px; min-height:560px" :style="{'background-image': 'url(' + required('bg-card-dashbord.jpg') + ')'}">
                            <div class="card-body p-4">
                                <h4 class="text-white" style="font-size: 45px; line-height: 45px; margin-top: 25px; margin-left: 10px;"><b>tudo em<br>um só<br>lugar</b></h4>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</div>
</template>
<script>
import ClientService from "@/services/resources/ClientService";
import EnterpriseService from "@/services/resources/EnterpriseService";
import states from '@/assets/js/states.json'
import ThemeService from "@/services/resources/ThemeService";

const service = ClientService.build();
const serviceTheme = ThemeService.build();
const serviceEnterprise = EnterpriseService.build();

export default {
   data(){
       return{
           client:{
               type: [],
               client_since_date: null,
               source: null,
               first_name: null,
               last_name: null,
               cpf: null,
               birth_date: null,
               gender: null,
               marital_status: null,
               alphabetization: null,
               nacionality: null,
               born_city: null,
               born_state: null,
               father_name: null,
               mother_name: null,
               rg: null,
               rg_emissor: null,
               rg_state: null,
               rg_date: null,
               rg_uf: null,
               email: null,
               telephone: null,
               cellphone: null,
               partner_first_name: null,
               partner_last_name: null,
               partner_cpf: null,
               partner_birth_date: null,
               cep: null,
               street: null,
               number: null,
               complement: null,
               neighborhood: null,
               city: null,
               state: null,
               residence_type: null,
               is_active: null,
               password: null,
               is_active: true,
               enterprises: []
           },
           address_error: false,
           settings_error: false,
           enterprise_error: false,
           active_enterprise: true,
           id: null,
           states: [],
           test: [],
           pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'perfil',
                    to: 'Profile'
                }
            ],
            menu:[],
            enterprise_id: null,
            floors: [],
            enterprises_list: [],
            floor: null
       }
   },
   computed:{
       user_id(){
           return this.$store.getters.getProfile.id;
       },
       isAdmin() {
        return this.$store.getters.isAdmin;
       }
   },
   methods:{
       required(image){
            if(image.includes('https')){
                return image;
            }

            return require(`@/assets/img/${image}`); 
       },
       getCities(uf){
           
           if(!uf)
                return []

           let state = this.states.filter(elem => {
               return elem.sigla === uf;
           });
           if(!state.length)
                return state;
                
           return state[0].cidades;

       },
       validateEnterpriseTab(){
           if(this.active_enterprise == 1 && this.client.enterprises.length == 0){
               this.enterprise_error = true;
               return false;
           }

           return true;
       },
       validateAddressTab(){
            if(!this.client.cep || !this.client.street || !this.client.number || !this.client.complement || !this.client.neighborhood || !this.client.city || !this.client.state || !this.client.residence_type){
                this.address_error = true;
                return false;
            }

           return true;
       },
       removeEnterprise(index){
           this.$delete(this.client.enterprises, index);
           this.$forceUpdate();
       },
       async addEnterprise(){
           if(!this.enterprise_id || this.floors.length == 0){
               this.$bvToast.toast('Selecione um imóvel e pelo menos um apartamento.', {
                    title: 'Adicionar imóvel',
                    autoHideDelay: 5000,
                    type: 'danger'
                })
               return;
           }
            let enterprise = await serviceEnterprise.read({id: this.enterprise_id}).then();
            enterprise.floors = this.floors;
            this.floors = [];
            this.enterprise_id = null;
            this.client.enterprises.push(enterprise);
       },
       getEnterprises(enterprises){
           let data = [];

            for(let i = 0; i < this.enterprises.length; i++){
                for(let j = 0; j < enterprises.length; j++){
                    if(this.enterprises[i].id == enterprises[j].enterprise_id){
                        data.push(this.enterprises[i]);
                        data.floors = enterprises[j].floors.map(elem => {
                            return elem.apto
                        });   
                    }
                }
            }

            return data;

       },
       save(){
        this.address_error = false;
        

        this.$validator.validateAll().then((result) => {
            if (result & this.validateAddressTab() & this.validateEnterpriseTab()) {
                let client = Object.assign(this.client)
                client.type = client.type.join(',');

                client.id = this.user_id;

                service
                .update(client)
                .then(resp => {
                this.$bvToast.toast('Seus dados foram atualizado com sucesso!', {
                    title: 'cliente atualizado',
                    autoHideDelay: 5000,
                    type: 'success'
                    })
                })
                .catch(err => {
                    this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                        title: 'Criação do cliente',
                        autoHideDelay: 5000,
                        type: 'danger'
                    })
                })

          
            }
        });
       },
       fetchTheme(){
           
            let data = {
                domain: window.location.hostname
            };

            serviceTheme
            .search(data)
            .then(resp => {
                resp.forEach(element => {
                    switch(element.key){
                        case 'PORTAL_CLIENT_ACTIVE_ENTERPRISE':
                            this.active_enterprise = element.value;
                            break;
                    }   
                });
                
            })
            .catch(err => {
            console.log(err)
            })
       },
       fetchClient(){


            let data = {
                id: this.user_id
            }

            service
            .read(data)
            .then(resp => {
                this.client = resp.client;
                this.client.first_name = resp.first_name;
                this.client.last_name = resp.last_name;
                this.client.cpf = resp.cpf;
                this.client.cellphone = resp.cellphone;
                this.client.email = resp.email;
                this.client.type = resp.client.type.split(',');
                this.enterprises_list = resp.enterprises;
                this.client.is_active = this.client.is_active == 1;
                this.client.enterprises = resp.enterprises.map(elem => {
                    let data = elem.enterprise;
                    data.floors = elem.floors;
                    return data;
                });
            })
            .catch(err => {
            console.log(err)
            })

       },
   },
   mounted(){
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
       this.states = states;
       this.fetchClient();
       this.fetchTheme();
   }
}
</script>